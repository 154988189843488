import "./App.css";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";

// import { db } from "firebase-config";
import { collection, addDoc, serverTimestamp, getDocs, where } from "firebase/firestore";
import { doc, setDoc, getDoc } from "firebase/firestore"; // Import necessary functions from Firebase Firestore



// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

function App() {

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [cardState, setCardState] = useState(0);
  const [cardText, setCardText] = useState({
    placeholder: "Enter your name",
    button: "Next"
  })
  const [submitting, setSubmitting] = useState(() => {
    const storedValue = localStorage.getItem("submitting");
    return storedValue !== null ? JSON.parse(storedValue) : true;
  });
  const [welcomeText, setWelcomeText] = useState("Welcome to,");
  const [appText, setAppText] = useState("carbon");
  const [colors, setColors] = useState({
    buttonColor: "black",
    color: "white",
  });

  useEffect(() => {
    localStorage.setItem("submitting", submitting);
  }, [submitting]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (cardState === 0) {
      if (!validateName(name)) {
        setWelcomeText("Please enter");
        setAppText("a valid name");
        return;
      }
      setCardState(1);
      setCardText({
        placeholder: "Enter your email",
        button: "Join Now!"
      });
      setWelcomeText("Welcome to,");
      setAppText("carbon");
    } else if (cardState === 1) {
      if (!validateEmail(email)) {
        setWelcomeText("A Valid Email");
        setAppText("Please");
        return;
      }

      try {
        const docRef = doc(db, "Waitlist_Emails", email);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setWelcomeText("Already");
          setAppText("Submitted");
          setCardState(2);
          setColors({
            buttonColor: "#F5F5F5",
            color: "#999999",
          });
          setCardText({
            placeholder: "",
            button: "Stay Tuned"
          });
          setSubmitting(false);
        } else {
          await setDoc(docRef, {
            created_at: serverTimestamp(),
            name: name,
            email: email,
          });

          console.log("Document written with ID: ", email);
          setSubmitting(false);
          setWelcomeText("Thank you for");
          setAppText("Joining");
          setCardState(2);
          setColors({
            buttonColor: "#F5F5F5",
            color: "#999999",
          });
          setCardText({
            placeholder: "",
            button: "Stay Tuned"
          });
        }
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    }
  };


  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        handleSubmit(event);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleSubmit]);

  const validateEmail = (email) => {
    // Regular expression for email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validateName = (name) => {
    const regex = /^[a-zA-Z\s]{2,}$/;
    return regex.test(name);
  };

  return (
    <div className="App">
      <div className="main">
        <div className="left-div">
          <div className="card">
            <div className="image-placeholder"><img src={require("./assets/gif.gif")} className="image" /></div>
            <p className="text">
              Our jaw-dropping dating app will be ready soon. Subscribe to be
              notified.
            </p>
            {cardState === 0 || cardState === 1 ? (
              <input type="input" value={cardState === 0 ? name : email} placeholder={cardText.placeholder} onChange={(event) => {
                if (cardState === 0) {
                  setName(event.target.value);
                } else if (cardState === 1) {
                  setEmail(event.target.value);
                }
              }}/>
            ) : 
              <div className="submitted">
                <a href="https://www.instagram.com/thecarbon.app/">Instagram</a>  
                <a href="https://x.com/0thbase">X(Twitter)</a>
                <a href="https://www.linkedin.com/company/0thcarbon">LinkedIn</a>
              </div>}
            <button onClick={(e) => handleSubmit(e)} style={{ backgroundColor : colors.buttonColor, color : colors.color }}>{cardText.button}</button>
          </div>
        </div>
          <motion.div
            key={`${welcomeText}-${appText}`}
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.8,
              delay: 0.5,
              ease: [0, 0.71, 0.2, 1.01]
            }}
            className="right-div">
            <p className="Welcome" >{welcomeText}</p>
            <p className="app" >{appText}</p>
          </motion.div>
      </div>
    </div>
  );
}

export default App;
